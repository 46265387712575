<template>
  <div class="video-player">
    <video ref="video" :src="adData.adUrl" autoplay :muted="isMuted" loop playsinline @canplaythrough="onVideoCanPlay"
           v-if="adData.adType === 'VIDEO'"></video>
    <div class="fullscreen-image" v-else>
      <img :src="adData.adUrl"/>
    </div>
    <div class="video-close">
      <div class="video-flex">
        <span>Récompense dans </span>
        <span>{{ authTime }}</span>
        <span> s</span>
      </div>
      <img src="../../assets/img/close.png" class="close-img" @click="openDialog"/>
    </div>
    <div class="muted-change">
      <img :src="mutedImgUrl" class="muted-img" @click="toggleMute"/>
    </div>
    <van-popup v-model="showThank" :close-on-click-overlay="false">
      <div class="thank-dialog">
        <div class="thank-info">
          <div>Regardez 30s pour la récompense.</div>
          <div>Quitter?</div>
        </div>
        <div class="thank-bt">
          <div class="img-bt" @click="abandonner">Quitter</div>
          <div class="img-bt" @click="continuer">Continuer</div>
        </div>
        <img src="../../assets/img/close.png" class="close-thank-img" @click="continuer"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'advertising',
  data () {
    return {
      isMuted: true,
      mutedImgUrl: require('../../assets/img/stop.png'),
      authTime: 30,
      time: null,
      showThank: false,
      resetTime: 0,
      adData: {
        adType: 'VIDEO'
      },
      code: ''
    }
  },
  watch: {
    isMuted: {
      handler: function (newVal, oldVal) {
        this.mutedImgUrl = newVal ? require('../../assets/img/stop.png') : require('../../assets/img/start.png')
      },
      immediate: false
    }
  },
  beforeDestroy () {
    // 在组件销毁前清除定时器，以防止内存泄漏
    clearInterval(this.time)
  },
  async mounted () {
    this.code = this.$route.query.code
    await this.getAd()
  },
  methods: {
    toggleMute () {
      this.isMuted = !this.isMuted
      this.$refs.video.muted = this.isMuted
    },
    // 获取广告
    async getAd () {
      const { data } = await this.$api.game.getAd()
      if (data) {
        this.adData = data.adInfo
        this.countDown()
        this.onVideoCanPlay()
      }
    },
    openDialog () {
      if (this.adData.adType === 'VIDEO') this.$refs.video.pause()
      clearInterval(this.time)
      this.showThank = true
    },
    abandonner () {
      this.showThank = false
      this.$router.go(-1)
    },
    // 继续光看广告
    continuer () {
      this.countDown()
      this.showThank = false
      if (this.adData.adType === 'VIDEO') this.$refs.video.play()
    },
    onVideoCanPlay () {
      if (this.adData.adType === 'VIDEO') this.$refs.video.play()
    },
    // 计时器
    countDown () {
      this.time = setInterval(() => {
        if (this.authTime !== 1) {
          this.authTime--
        } else {
          clearInterval(this.time)
          this.authTime = 30
          this.$router.push({
            path: `/tj6ckfmk9h/${this.code}`,
            query: { again: '1' }
          })
        }
      }, 1000)
    }
  },
  components: {}
}
</script>

<style scoped lang="scss">
html, body, .video-player, video {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

::v-deep .van-popup {
  background: transparent;
}

.thank-dialog {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-top: 40px;

  .thank-info {
    width: 386px;
    height: 176px;
    background-image: url('../../assets/img/thank.png'); /* 替换为你的图片路径 */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    text-align: center;
    border-radius: 20px;
    color: #ffffff;
    font-size: 24px;
    padding: 50px 20px 0;
  }

  .close-thank-img {
    width: 27px;
    height: 27px;
    position: absolute;
    top: 0;
    right: 60px;
    z-index: 9999;
  }

  .thank-bt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    box-sizing: border-box;
    padding: 0 20px;

    .img-bt {
      width: 150px;
      height: 50px;
      background-image: url('../../assets/img/tank-bt.png'); /* 替换为你的图片路径 */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
  }
}

.video-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;

  .fullscreen-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; /* 确保图片在最底层 */
  }

  .fullscreen-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .muted-change {
    position: absolute;
    top: 50px;
    left: 50px;
    .muted-img {
      width: 60px;
      height: 60px;
    }
  }

  .video-close {
    position: absolute;
    color: #ffffff;
    top: 40px;
    right: 50px;
    display: flex;
    align-items: center;

    .video-flex {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 18px 40px;
      background: #303333;
      border-radius: 40px;
    }

    .close-img {
      width: 60px;
      height: 60px;
      margin-left: 10px;
    }
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Hide controls */
  -webkit-appearance: none;
  appearance: none;
}
</style>
